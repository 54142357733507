import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../../components/Table.jsx';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { privateContent } = this.props;
        const cheques = privateContent?.checks;

        return (
            <>
                <div className="profileCheques">
                    <div className="profileCheques__inner">
                        <div className="profileCheques__title">Мои чеки</div>
                        <div className="profileCheques__table">
                            <Table
                                name="cheques"
                                items={cheques || []}
                                isReady={!!cheques}
                                emptyTitle="Чеков пока нет"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    device: PropTypes.string,
    privateContent: PropTypes.object,
};
