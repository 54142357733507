import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../components/Button.jsx';
import setSpacesInText from '../../functions/setSpacesInText';
import Link from '../../components/Link.jsx';

class IndexPrize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { components, user } = this.props;
        const prize = components?.prizes;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexPrize ${components ? '_ready' : ''}`}
                    id="prizes"
                >
                    <div className="indexPrize__inner">
                        <div className="indexPrize__title">{prize?.title?.title}</div>
                        <div className="indexPrize__prize">
                            <img
                                src={prize?.title?.thumb}
                                alt=""
                                className="indexPrize__prizeImage"
                            />
                        </div>
                        <div
                            className="indexPrize__description"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(prize?.title?.description),
                            }}
                        ></div>
                        <Link className="indexPrize__button" pageName={user ? 'cheques' : 'login'}>
                            <Button className="_orange">{prize?.title?.button}</Button>
                        </Link>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexPrize);

IndexPrize.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
