import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import setSpacesInText from '../../functions/setSpacesInText';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { privateContent } = this.props;
        const items = privateContent?.prizes;

        return (
            <>
                <div className={`profilePrize ${privateContent ? '_ready' : ''}`}>
                    <div className="profilePrize__inner">
                        <div className="profilePrize__title">Мои призы</div>
                        <div className="profilePrize__content">
                            <div className="profilePrize__items">
                                {items?.map((item, key) => (
                                    <div className="profilePrize__item" key={key}>
                                        <div className="profilePrize__prize">
                                            <div className="profilePrize__prizeHead">
                                                <img
                                                    src={item.thumb}
                                                    alt=""
                                                    className="profilePrize__prizeImage"
                                                />
                                            </div>
                                            <div
                                                className="profilePrize__prizeName"
                                                dangerouslySetInnerHTML={{
                                                    __html: setSpacesInText(item.title),
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    device: PropTypes.string,
    privateContent: PropTypes.object,
};
