import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../functions/setSpacesInText';

import Icon from '../../components/Icon.jsx';
import removeTransition from '../../functions/removeTransition';
import Loader from '../../components/Loader.jsx';
import Animate from '../../components/Animate.jsx';

class FaqHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setSize = this.setSize.bind(this);

        this.parent = React.createRef();
    }

    handlerCard({ key }) {
        const card = this.parent.current.querySelector(`.faqHeader__card[data-key="${key}"]`);

        this.setState((state) => {
            const newState = { ...state };
            const cards = [...newState.cards];

            const cardIndex = cards.findIndex((item) => item.key === key);

            if (cardIndex !== -1) {
                cards[cardIndex].isActive = !cards[cardIndex].isActive;
                cards[cardIndex].height = card.querySelector(
                    '.faqHeader__cardContentInner',
                ).offsetHeight;
            }

            newState.cards = cards;

            return newState;
        });
    }

    setSize() {
        this.setState((state) => {
            const newState = { ...state };
            const cards = [...(newState.cards || [])];

            cards.forEach(({ key }, index) => {
                const card = this.parent.current.querySelector(
                    `.faqHeader__card[data-key="${key}"]`,
                );

                cards[index].height = card.querySelector(
                    '.faqHeader__cardContentInner',
                ).offsetHeight;
            });

            newState.cards = cards;

            return newState;
        });
    }

    checkContent() {
        const { faq } = this.props;

        if (faq && !this.isGetContent) {
            this.isGetContent = true;

            const items = faq || [];

            this.setState({ items }, () => {
                removeTransition({ item: '.faqHeader__card' });

                this.setState(
                    {
                        cards: items.map((item, key) => {
                            const card = this.parent.current.querySelector(
                                `.faqHeader__card[data-key="${key}"]`,
                            );

                            return {
                                key,
                                height: card.querySelector('.faqHeader__cardContentInner')
                                    .offsetHeight,
                                isActive: key === 0 && false,
                            };
                        }),
                    },
                    () => {
                        const inner = this.parent.current.querySelector('.faqHeader__contentInner');

                        this.setState({ contentHeight: inner.offsetHeight });

                        setTimeout(() => {
                            this.setState({ isReady: true });
                        }, 100);

                        setTimeout(() => {
                            this.setState({ contentHeight: null });
                        }, 400);
                    },
                );
            });
        }
    }

    componentDidMount() {
        this.checkContent();

        document.addEventListener('changeWidthWindow', this.setSize);
    }

    componentDidUpdate() {
        this.checkContent();
    }

    componentWillUnmount() {
        document.removeEventListener('changeWidthWindow', this.setSize);
    }

    render() {
        const { cards = [], items = [], isReady, contentHeight } = this.state;

        return (
            <>
                <div ref={this.parent} className={`faqHeader ${isReady ? '_ready' : ''}`} id="faq">
                    <div className="faqHeader__inner">
                        <h1 className="faqHeader__title">Вопрос-ответ</h1>
                        <div
                            className="faqHeader__content"
                            style={
                                !isReady
                                    ? { height: '25rem' }
                                    : contentHeight
                                    ? { height: `${contentHeight}px` }
                                    : {}
                            }
                        >
                            <Animate className="faqHeader__loader" isShow={!isReady}>
                                <div className="faqHeader__loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="faqHeader__contentInner">
                                {items.map((card, key) => {
                                    const stateCard = cards.find((item) => item.key === key);
                                    const isActive = !!stateCard?.isActive;

                                    return (
                                        <div
                                            className={`faqHeader__card ${
                                                isActive ? '_active' : ''
                                            }`}
                                            key={key}
                                            data-key={key}
                                        >
                                            <div
                                                className="faqHeader__cardHead"
                                                onClick={() => {
                                                    this.handlerCard({ key });
                                                }}
                                            >
                                                <div
                                                    className="faqHeader__cardTitle"
                                                    dangerouslySetInnerHTML={{
                                                        __html: setSpacesInText(card.title),
                                                    }}
                                                ></div>
                                                <i className="faqHeader__cardHeadIcon">
                                                    <Icon name="select" />
                                                </i>
                                            </div>
                                            <div
                                                className="faqHeader__cardContent"
                                                style={{
                                                    height: `${isActive ? stateCard?.height : 0}px`,
                                                }}
                                            >
                                                <div
                                                    className="faqHeader__cardContentInner"
                                                    dangerouslySetInnerHTML={{
                                                        __html: setSpacesInText(card.description),
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(FaqHeader);

FaqHeader.propTypes = {
    device: PropTypes.string,
    faq: PropTypes.object,
    scrollToForm: PropTypes.func,
};
