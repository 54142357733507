import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Icon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    init() {
        const script = document.createElement('script');

        script.src = './cars/content.js';

        script.onload = () => {
            const script2 = document.createElement('script');
            script2.src = './cars/data.js';
            script2.onload = () => {
                window.animateInit?.();
            };
            document.body.appendChild(script2);
        };

        document.body.appendChild(script);
    }

    componentDidMount() {
        setTimeout(() => {
            this.init();
        }, 10);
    }

    render() {
        return (
            <div
                id="animation_container"
                style={{
                    position: 'absolute',
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    width: '100%',
                    height: '100%',
                }}
            >
                <canvas
                    id="cvr_cnvs"
                    width="100%"
                    height="100%"
                    style={{
                        position: 'absolute',
                        display: 'block',
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                    }}
                ></canvas>
                <div
                    id="dom_overlay_container"
                    style={{
                        pointerEvents: 'none',
                        overflow: 'hidden',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        top: 0,
                        display: 'block',
                    }}
                ></div>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '1.1px',
                        height: '100%',
                        backgroundColor: '#000',
                    }}
                ></div>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '1.1px',
                        backgroundColor: '#000',
                    }}
                ></div>
                <div
                    style={{
                        position: 'absolute',
                        left: '100%',
                        transform: 'translateX(-1.1px)',
                        top: 0,
                        width: '1.1px',
                        height: '100%',
                        backgroundColor: '#000',
                    }}
                ></div>
                <div
                    style={{
                        position: 'absolute',
                        left: '0',
                        top: '100%',
                        transform: 'translateY(-1.1px)',
                        height: '1.1px',
                        width: '100%',
                        backgroundColor: '#000',
                    }}
                ></div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Icon);

Icon.propTypes = {
    info: PropTypes.object,
    name: PropTypes.string,
    data: PropTypes.object,
};
