import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import handlerLoading from '../../functions/handlerLoading';
import logout from '../../functions/logout';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';
import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    logout() {
        handlerLoading.call(this, 'logout').then(() => {
            logout().then(
                () => {
                    handlerLoading.call(this, null);
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { user } = this.props;

        return (
            <>
                <div className="profileHeader">
                    <div className="profileHeader__inner">
                        <div className="profileHeader__title">Личный кабинет</div>
                        <div className="profileHeader__content">
                            <div className="profileHeader__block">
                                <div className="profileHeader__name">
                                    {user?.personal?.firstName} {user?.personal?.lastName}
                                </div>
                                <Link className="profileHeader__link" href="anket">
                                    изменить данные
                                </Link>
                                <div
                                    className="profileHeader__exit"
                                    onClick={this.logout.bind(this)}
                                >
                                    <Animate
                                        className="profileHeader__exitLoader"
                                        isShow={loadingKey === 'logout'}
                                    >
                                        <Loader />
                                    </Animate>
                                    Выход
                                </div>
                            </div>
                            <div className="profileHeader__block">
                                <Link className="profileHeader__button" pageName="cheques">
                                    <Button className="_orange _dark">Регистрация чека</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
};
