const pages = require('../redux/pages').default;

export default function getNav({ user }) {
    const navOrder = ['index', 'prizes', 'winners', 'faq'];
    const ancors = {
        index: {
            content: 'Главная',
        },
        winners: {
            content: 'Победители',
        },
        prizes: {
            content: 'Призы',
        },
        faq: {
            content: 'Вопрос-ответ',
        },
    };

    return navOrder.map((name) => {
        const info = pages.find((page) => page.name === name) || ancors[name];
        const isAncor = !!ancors[name] || name === 'index';
        let isPopup = info?.isPopup;

        if (name === 'profile' && !user) {
            name = 'login';
            isPopup = true;
        }

        return { name, content: info?.content, isAncor, isPopup };
    });
}
