import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '../../components/Button.jsx';
import AnimateChange from '../../components/AnimateChange.jsx';
import Animate from '../../components/Animate.jsx';
import FormPage from '../../components/FormPage.jsx';
import handlerLoading from '../../functions/handlerLoading';
import getHeaders from '../../functions/getHeaders';
import changePage from '../../functions/changePage';
import checkAuth from '../../functions/checkAuth';
import saveJWT from '../../functions/saveJWT';
import requestSuccess from '../../functions/requestSuccess';

class AnketForm extends FormPage {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: [],
        };

        this.sendAnket = this.sendAnket.bind(this);

        this.parent = React.createRef();
    }

    fieldsOrder = [
        'firstName',
        'secondName',
        'phone',
        'password',
        'againPassword',
        'policy',
        'mailing',
    ];

    getFields() {
        const { user } = this.props;
        const fieldsOrder = [...this.fieldsOrder];

        if (user?.isFirstAnket) {
            fieldsOrder.unshift('referral');
        }

        return fieldsOrder;
    }

    fields = {
        referral: {
            support: 'Код пригласившего участника (если есть)',
        },
        firstName: {
            support: 'Имя*',
        },
        secondName: {
            support: 'Фамилия*',
        },
        thirdName: {
            support: 'Отчество',
        },
        phone: {
            support: 'Номер мобильного телефона*',
        },
        password: {
            support: () => `Пароль${this.props.user?.status === 'PARTICIPANT' ? '' : '*'}`,
        },
        againPassword: {
            support: () => `Повтор пароля${this.props.user?.status === 'PARTICIPANT' ? '' : '*'}`,
        },
        policy: {
            type: 'checkbox',
            support: () => {
                const { components } = this.props;
                const policy = components?.footer?.url2?.url;
                // const rules = components?.footer?.url3?.url;

                return (
                    <>
                        Я соглашаюсь с{' '}
                        <a href={policy} target="_blank" rel="noreferrer">
                            политикой конфиденциальности
                        </a>{' '}и{' '}
                        <a href="/upload/docs/agreement.pdf" target="_blank" rel="noreferrer">
                            условиями обработки персональных данных
                        </a>
                        *
                    </>
                );
            },
        },
        mailing: {
            type: 'checkbox',
            support: (
                <>
                    Я{' '}
                    <a href="/upload/docs/mailing.pdf" target="_blank" rel="noreferrer">
                        соглашаюсь
                    </a>{' '}
                    получать рекламно-информационную рассылку
                </>
            ),
        },
    };

    sendAnket() {
        const { fields } = this.state;
        const body = {};

        if (fields.referral) {
            body.referral = fields.referral;
        }

        body.firstName = fields.firstName;
        body.lastName = fields.secondName;
        body.phone = fields.phone;
        body.password1 = fields.password;
        body.password2 = fields.againPassword;
        body.agreement = !!fields.policy;
        body.mailing = !!fields.mailing;

        handlerLoading.call(this, true, { error: null }).then(() => {
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/SendParticipantInfo`,
                    {
                        ...body,
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        requestSuccess(res);

                        const { result, JWT, data } = res.data;

                        if (result === 'OK') {
                            saveJWT(JWT);

                            localStorage.removeItem('inviteCode');

                            checkAuth().then(() => {
                                handlerLoading.call(this, null);

                                changePage({ href: 'profile' });
                            });

                            if (data?.isFirstAnket) {
                                if (window.YANDEX_ID) {
                                    window[`yaCounter${window.YANDEX_ID}`].reachGoal('anket');
                                }
                            }
                        } else {
                            handlerLoading.call(this, null);
                        }
                    },
                    (err) => {
                        handlerLoading.call(this, null);

                        try {
                            const { errorText } = err.response.data;

                            this.setState({ error: errorText });
                        } catch (error) {
                            this.setState({
                                error: 'Ошибка сервера',
                            });
                        }
                    },
                );
        });
    }

    componentDidMount() {
        super.componentDidMount();

        const { user } = this.props;

        if (user) {
            const fields = {};

            if (localStorage.getItem('inviteCode') && user.isFirstAnket) {
                fields.referral = localStorage.getItem('inviteCode');
            }

            if (user.personal.firstName) {
                fields.firstName = user.personal.firstName;
            }

            if (user.personal.lastName) {
                fields.secondName = user.personal.lastName;
            }

            if (user.personal.phone) {
                fields.phone = user.personal.phone;
            }

            if (user.status === 'PARTICIPANT') {
                fields.policy = true;
            }

            if (user.mailing === '1') {
                fields.mailing = true;
            }

            this.setState({ fields });
        }
    }

    render() {
        const {
            error,
            isSuccess = false,
            isCompleteSuccess,
            formHeight = 0,
            loadingKey,
        } = this.state;
        const { user } = this.props;

        return (
            <>
                <div ref={this.parent} className="faqForm _anket">
                    <div className="faqForm__inner">
                        <div className="faqForm__content">
                            <div className="faqForm__contentInner">
                                <div
                                    className={`form ${isCompleteSuccess ? '_success' : ''}`}
                                    style={isSuccess ? { height: `${formHeight}px` } : {}}
                                >
                                    <Animate className="form__success" isShow={isSuccess}>
                                        <div className="form__successContent">
                                            <span>Спасибо, Лариса!</span>
                                            Сообщение отправлено, мы свяжемся с&nbsp;вами
                                            в&nbsp;ближайшее время.
                                        </div>
                                    </Animate>
                                    <div className="form__inner">
                                        <div className="form__fields">
                                            {this.getFields().map((name) =>
                                                this.renderField({ name }),
                                            )}
                                        </div>
                                        <div className="form__required">*Обязательные поля</div>
                                        <AnimateChange
                                            className="form__error _center"
                                            renderKey={error}
                                            paramsParent={{
                                                width: document.querySelector('.form__inner')
                                                    ?.offsetWidth,
                                            }}
                                            itemParams={['width']}
                                        >
                                            {error ? <div className="error">{error}</div> : null}
                                        </AnimateChange>
                                        <div className="form__button">
                                            <Button
                                                className="_orange"
                                                onClick={this.sendAnket}
                                                loader={!!loadingKey}
                                            >
                                                {user?.isFirstAnket ? (
                                                    <>Завершить регистрацию</>
                                                ) : (
                                                    <>ОТПРАВИТЬ</>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(AnketForm);

AnketForm.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
